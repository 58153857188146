<template>
  <div class="ce-wrapper">
    <div v-if="isLoading" class="text-center">
      <LoaderPlaceholder class="loader" type="circle" />
    </div>

    <div v-else>
      <div class="d-flex">
          <span class="config-title">{{orgName}} Billing Config</span>
          <AddCancelBtn
              v-if="org_id "
              :key="org_id"
              class="ml-auto"
              :onClick="clearForm"
              :isCancel="true"
          />

      </div>
      <div>
        <div class="d-flex form-inline p-3">
          <div v-if="!org_id" class="form-group">
            <label for="org-selector">Org:</label>
            <select
                class="form-control input-sm"
                v-model="org_id"
                id="org-selector"
                @change="orgChanged"
            >
              <option disabled value="">Please select one</option>
              <option
                  v-for="org in availableOrgs"
                  :value="org.id"
                  :key="org.id"
              >
                {{ org.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="details" class="editor-wrapper">
        <div v-if="isLoadingConfig" class="text-center">
          <LoaderPlaceholder class="loader" type="circle" />
        </div>
        <div v-else @mouseover="addPreview" @mouseout="removePreview">
          <div class="form-group addon-select">
            <label for="org-selector">Addons</label>
            <select
                class="form-control input-sm"
                v-model="newAddon"
                id="addon-selector"
                @change="addonSelected"
            >
              <option disabled value="">Please select one</option>
              <option
                  v-for="addon in availableAddons"
                  :value="addon.type"
                  :key="addon.type"
              >
                {{ addon.label }}
              </option>
            </select>
          </div>
          <vjsoneditor
              v-model="details"
              :plus="true"
              :options="options"
              :height="height"
              @error="onError"
          />
        </div>

        <div class="d-flex pt-3">
          <div class="ml-auto">
            <BaseButton
                v-if="changed && !addingNew"
                class="mr-2"
                @click="saveConfig"
            >
              Save
            </BaseButton>
            <BaseButton
                v-if="addingNew && org_id"
                class="mr-2"
                @click="handleAddConfig"
            >
              Add
            </BaseButton>

            <BaseButton
                v-if="config || !addingNew"
                outline
                @click="handleDeleteConfig"
            >
              Delete
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <div :id="tooltipID" class="ce_tooltip" role="tooltip"></div>
  </div>
</template>

<script>
import vjsoneditor from "v-jsoneditor"
import { createPopper } from "@popperjs/core"
import AddCancelBtn from "../AddCancelBtn"
import ConfigNameInput from "../ConfigNameInput"
import {bbRateSubscription} from "../../bb-api";
import md5 from "js-md5";

export default {
  name: "ConfigEditorManager",
  components: {
    vjsoneditor,
    AddCancelBtn,
    ConfigNameInput
  },
  data() {
    const vm = this
    return {
      tooltipID: `prev-wrapper-${Date.now()}`,
      selectedConfigId: "",
      selectedOrgId: "",
      isLoading: true,
      isLoadingConfig: true,
      org_id: "",
      config: null,
      details: null,
      originalDetails: null,
      height: "400px",
      changed: false,
      addingNew: false,
      configName: null,
      isDefault: false,
      availableOrgs: null,
      availableConfig: [],
      options: {
        onChangeText(configJson) {
          vm.changed = true
        }
      },
      defaultDetails: {
        level: "launch",
        addons: []
      },
      newAddon: null,
      availableAddons: [
        {
          type: "landing_page",
          label: "Landing Page",
          monthly_price: 100,
          description: "Landing Page Addon"
        },
        {
          type: "custom_domain",
          label: "Custom Domain",
          monthly_price: 100,
          description: "Custom Domain Addon"
        },
        {
          type: "itemized_fees",
          label: "Itemized Fees",
          monthly_price: 50,
          description: "Itemized Fees Addon"
        },
        {
          type: "custom_fee",
          label: "Custom Fee",
          monthly_price: 650,
          description: "Custom Fee Addon"
        },
        {
          type: "discount",
          label: "Discount",
          monthly_price: 50,
          description: "Monthly discount subtracted from total"
        }
      ],
      availableLevels: [
        {
          value: "growth",
          label: "Growth"
        },
        {
          value: "core",
          label: "Core"
        },
        {
          value: "launch",
          label: "Launch"
        },
        {
          value: "custom",
          label: "Custom"
        }
      ]
    }
  },
  async mounted() {
    // Get the available orgs to fill the dropdown
    await this.getAvailableOrgs()
    // Get the config id from the url(if any)
    const urlParams = new URLSearchParams(window.location.search)
    // If there is a config id in the url, load it
    if (urlParams.has("org_id")) {
      this.selectedOrgId = urlParams.get("org_id")
      await this.handleGetOrgConfig(this.selectedOrgId)
    }
    if (urlParams.has("id")) {
      this.selectedConfigId = urlParams.get("id")
      await this.handleGetConfig(this.selectedConfigId)
    }
    // get the available configs
    await this.handleGetAllConfig()
  },
  computed: {
    orgName() {
      let org=this.getOrgById(this.org_id)
      return org ? org.name : ""
    }
  },
  methods: {
    async handleGetAllConfig() {
      const res = await this.getAllConfig()
      this.getAllConfigCallBack(res)
    },
    async getAllConfigCallBack(res) {
      // Check if there are any configs
      if (Array.isArray(res)) {
        this.availableConfig = res
        if (!this.selectedConfigId) {
          // If there is no selected config find if there is a default config
          /*this.availableConfig.forEach(config => {
            if (config.is_default == "1") {
              this.selectedConfigId = config.id
              this.changeSelectedConfig(this.selectedConfigId)
            }
          })*/
        }
      } else {
        this.availableConfig = []
      }
      this.isLoading = false
    },
    getOrgById(id) {
      return this.availableOrgs.find(org => org.id === id)
    },
    configChanged() {
      this.changed = true
    },
    orgChanged() {
      // add org_id to the url
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has("id")) {
        window.history.pushState(
            {},
            null,
            `/config-admin?id=${this.selectedConfigId}&org_id=${this.org_id}${window.location.hash}`
        )
      } else {
        window.history.pushState(
            {},
            null,
            `/config-admin?org_id=${this.org_id}${window.location.hash}`
        )
      }
      this.handleGetOrgConfig(this.org_id)
    },
    addPreview(e) {
      // check event target class name
      if (e.target.className.includes("jsoneditor-url")) {
        const previewWrapper = document.getElementById(this.tooltipID)

        previewWrapper.innerHTML = `<img style="max-width:100%" src="${e.target.innerText}"><div class="ce_tooltip-arrow" data-popper-arrow></div>`
        this.popperInstance = createPopper(e.target, previewWrapper, {
          placement: "top"
        })
      }
    },
    removePreview(e) {
      if (e.target.className.includes("jsoneditor-url")) {
        this.popperInstance.destroy()
      }
    },
    onError() {
      console.error("Editor error")
    },
    async handleGetOrgConfig(org_id){
      this.isLoadingConfig = true
      this.changed = false
      const vm = this
      vm.org_id = org_id || ""
      let config=await this.getConfig({org_id})
      this.originalDetails=config.details
      // Check if it was not found
      // console.log(config);
      if (config && config.id) {
        vm.selectedConfigId = config.id
        vm.details = config.details
        vm.addingNew = false
      } else {
        vm.selectedConfigId = ""
        vm.details = vm.defaultDetails
        vm.addingNew = true
      }
      vm.isLoadingConfig = false
    },
    async handleGetConfig(id) {
      this.isLoadingConfig = true
      this.changed = false
      const vm = this
      if(!id){
        vm.config={
          level: "",
          addons: []
        }
        return
      }
      let config = await this.getConfig({id})
      // Check if it was not found
      if (config && config.id) {
        vm.selectedConfigId = config.id
        vm.org_id = config.org_id
        vm.details = config.details
        vm.addingNew = false
      } else {
        vm.selectedConfigId = ""
        vm.details = vm.defaultDetails
        vm.addingNew = true
      }
      vm.isLoadingConfig = false
    },
    async handleAddConfig() {
      // console.log("handleAddConfig", this.org_id)
      const payload = {
        org_id: this.org_id,
        details: this.details
      }

      // check if level is valid
      if(this.details.level && !this.availableLevels.find(level => level.value === this.details.level)){
        BB.Toastr.error("Invalid level: "+this.details.level)
        return
      }

      const vm = this

      let result=await this.addConfig(payload)

      if(result.status==="error" || !result.id){
        BB.Toastr.error("Billing config not added.")
        return
      }

      vm.changed = false
      vm.addingNew = false
      vm.selectedConfigId = result.id
      vm.changeSelectedConfig()
      vm.saveCallback("Config added successfully.")
    },
    saveConfig() {
      const saveCallback = this.saveCallback
      const payload = {
        id: this.selectedConfigId,
        org_id: this.org_id,
      }

      // check if level is valid
      if(this.details.level && !this.availableLevels.find(level => level.value === this.details.level)){
        BB.Toastr.error("Invalid level: "+this.details.level)
        return
      }

      payload.details = this.details
      this.changed = false
      this.updateConfig(payload, () => {
        saveCallback("Config saved successfully.")
      })

    },
    saveCallback(msg) {
      BB.Toastr.success(msg)
    },
    clearForm(getAll = true) {
      this.changed = false
      this.org_id = ""
      this.config = null
      this.details = null
      this.selectedConfigId = ""
      this.selectedOrgId = ""
      this.configName = ""
      window.history.pushState(
          {},
          null,
          `/config-admin${window.location.hash}`
      )
      if (getAll) {
        this.addingNew = false
        this.handleGetAllConfig()
      }
    },
    async getAvailableOrgs() {
      let orgs=(await BB.orgManage.get({limit: 1000})).data
      // sort orgs by name
      orgs.sort((a, b) => a.name.localeCompare(b.name))
      this.availableOrgs = orgs
    },
    changeSelectedConfig() {
      this.handleGetConfig(this.selectedConfigId)
      window.history.pushState(
          {},
          null,
          `?id=${this.selectedConfigId}${window.location.hash}`
      )
    },
    addNew() {
      // TODO: let user cancel and show the config selector.
      if (this.addingNew || this.config) {
        this.addingNew = false
        this.config = null
      } else {
        this.addingNew = true
        this.handleGetConfig(null)
      }
      this.clearForm(false)
    },
    async getAllConfig() {

    },
    addonSelected(){
      // find the selected addon within the available addons and add it to the details
      const selectedAddonDetails = this.availableAddons.find(addon => addon.type === this.newAddon)
      if(selectedAddonDetails){
        let newDetails = Object.assign({}, this.details)
        if(!newDetails.addons)newDetails.addons = []
        newDetails.addons.push(selectedAddonDetails)
        this.details = newDetails
      }
      this.changed = true
      this.newAddon = null
    },
    async getConfig(params) {
      if(params.id){
        return BB.billingConfig.get({id:params.id})
      }
      if(params.org_id){
        return BB.billingConfig.get_from_org_id(params.org_id)
      }
    },
    async addConfig(payload) {
      return BB.billingConfig.add(payload)
    },
    updateConfig(payload, callBack) {
      BB.billingConfig.update(payload).then(callBack)
    },
    async deleteConfig(id) {

    },
    async handleDeleteConfig() {
      if (this.addingNew) {
        this.clearForm()
        return
      }
      if (confirm("Do you really want to delete this?")) {
        await this.deleteConfig(this.selectedConfigId)
        this.clearForm()
      }
    }
  }
}
</script>

<style lang="scss" src="./BillingManager.scss"></style>